<template>
  <div class="shadow-2xl rounded-3xl w-full mx-auto">
    <div class="intro-y box col-span-2">
      <div class="bg-white p-4 rounded shadow-sm">
        <table class="table table-bordered table-hover">
          <thead class="bg-primary text-purple">
            <tr>
              <th class="text-center">SL</th>
              <th class="text-center">Department</th>
              <th class="text-center">Title</th>
              <th class="text-center">Requestor</th>
              <th class="text-center">Status</th>
              <th class="text-center">Staff</th>
              <th class="text-center">Last Edit</th>
              <!-- <th class="text-center">Action</th> -->
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(ticket, index) in this.tickets"
              :key="index"
              class="bg-gray-100"
              @click="goToTicket(ticket.id)" 
              style="cursor: pointer;"
            >
            
              <!-- <tr class="bg-gray-100"> -->
              <!-- <tr v-for="(ticket, index) in tickets" :key="index" class="bg-gray-100"> -->
              <td class="text-center">{{ index + 1 }}</td>
              <td class="text-center">
                {{ ticket.department.name }}
              </td>
              <td class="text-center">
                {{ ticket.title }}
              </td>
              <td class="text-center">
                {{ ticket.name }}
              </td>
              <td class="text-center" :class="statusClass(ticket.status)">
                {{ ticket.status }}
              </td>
              <td class="text-center">
                {{
                  ticket.staff && ticket.staff.name ? ticket.staff.name : "N/A"
                }}
              </td>
              <td class="text-center">
                {{ ticket.last_reply }}
              </td>
              <!-- <td class="text-center">
                <button
                  class="btn btn-danger"
                  @click="deleteTicket(ticket.id)"
                >
                  Delete
                </button>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BLink,
  BTooltip,
  BMedia,
  BBadge,
  BButton,
  VBToggle,
  BSidebar,
  BForm,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
// import store from '@/store/index'
import store from "@/store";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import useJwt from "@/auth/jwt/useJwt";
import vSelect from "vue-select";
import { required, email } from "@validations";
import { checkRes } from "@core/services/helper";
// Use toast
import Mixin from "@core/services/mixin";

import { ISP_LIST, GET_TICKETS, DELETE_TICKET,  } from "@core/services/api";
export default {
  mixins: [Mixin],
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BRow,
    BCol,
    BLink,
    BTooltip,
    BMedia,
    BBadge,
    BButton,
    BSidebar,
    BForm,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    flatPickr,
    ToastificationContent,
  },
  data() {
    return {
      isp_id: JSON.parse(localStorage.getItem("userData")),
      name: null,
      price: null,
      total: 0,
      total_ticket: 0,
      tickets: [],
      test: [],
      isp_id: JSON.parse(localStorage.getItem("userData")),
      from_date: "",
      to_date: "",
      payment_status: null,
      payment_method: null,
    };
  },
  methods: {
    statusClass(status) {
      switch (status) {
        case "open":
          return "text-blue";
        case "closed":
          return "text-red";
        case "on_hold":
          return "text-yellow";
        case "in_progress":
          return "text-green";
        case "answered":
          return "text-pink";
        default:
          return ""; // fallback if status doesn't match
      }
    },
    goToTicket(ticketId) {
      this.$router.push({ name: 'TicketDetails', params: { id: ticketId } });
    },
    FetchTicketData() {
      this.$http
        .get(process.env.VUE_APP_BASEURL + GET_TICKETS, {
          params: {
            isp_id: this.isp_id.id,
            status: this.status,
          },
        })
        .then((res) => {
          console.log(res.data);
          console.log("hi");
          this.tickets = res.data.data.tickets;
        })
        .catch((error) => {
          if (error.response.payment_status === 404) {
            userData.value = undefined;
          }
        });
    },
    deleteTicket(ticketId) {
      if (confirm("Are you sure you want to delete this ticket?")) {
        this.$http
          .post(process.env.VUE_APP_BASEURL + DELETE_TICKET, {
            ticket_id: ticketId,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Ticket deleted successfully!",
                variant: "success",
              },
            });
          })
          .catch((err) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Failed to delete the ticket.",
                variant: "danger",
              },
            });
          });
          this.fetchTickets();
      }
      
    },
  },
  mounted() {
    this.FetchTicketData();
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.text-blue {
  color: blue;
}
.text-red {
  color: red;
}
.text-yellow {
  color: rgb(255, 166, 0);
}
.text-green {
  color: green;
}
.text-pink {
  color: pink;
}
.error-message {
  color: red;
}
</style>
